import DoubleboggeyP from "../../assets/img/DoubleboggeyP.png";
import Michelangelo from "../../assets/img/Michelangelo.png";
import Nearide from "../../assets/img/Nearide.png";
import onepunchman from "../../assets/img/onepunchman.jpg";
import PhotoProfil from "../../assets/img/Photo_profil.png";
import Studiosupram from "../../assets/img/studiosupram.jpg";
import Seqens from "../../assets/img/seqens.jpg";
import Dashboard from "../../assets/img/obhyundai.png";
import Minifier from "../../assets/img/obkia.png";
import Polluscore from "../../assets/img/megalp.png";

const images = {
  DoubleboggeyP,
  Michelangelo,
  Nearide,
  onepunchman,
  PhotoProfil,
  Studiosupram,
  Seqens,
  Dashboard,
  Minifier,
  Polluscore,
};

export default images;
